import {trans} from "../../functions";
import noUiSlider from "nouislider";

export default {
    components: {VPagination: () => import("../VPagination.vue")},

    props: [
        "initialQuery",
        "initialBrandName",
        // "initialBrandBanner",
        "initialBrandSlug",
        "initialCategoryName",
        // "initialCategoryBanner",
        // "initialCategorySlug",
        "initialTagName",
        "initialTagSlug",
        "initialAttribute",
        "minPrice",
        "maxPrice",
        "initialSort",
        "initialPerPage",
        "initialPage",
        "initialViewMode",
        "initialOriginSlug",
        "initialTypeSlug",
        "initialGrapeSlug",
        "initialGrapeName",
        "initialKeyword",
        "initialRootCategorySlug",
        "initialCategorySlug",
        "initialOriginName",
        "initialTypeName",
        "initialCategoryMultiSlug",
    ],

    data() {
        return {
            fetchingProducts: false,
            fetchingBrands: false,
            fetchingOrigins: false,
            fetchingGrapes: false,
            products: {data: []},
            attributeFilters: [],
            rootCategorySlug: this.initialRootCategorySlug,
            categorySlug: this.initialCategorySlug,
            // brandBanner: this.initialBrandBanner,
            // categoryBanner: this.initialCategoryBanner,
            categoryName: this.initialCategoryName,
            brandName: this.initialBrandName,
            originName: this.initialOriginName,
            typeName: this.initialTypeName,
            viewMode: this.initialViewMode,
            grapeName: this.initialGrapeName,
            brands: {data: []},
            origins: {data: []},
            grapes: {data: []},
            searchedName: {
                categoryName: [],
                brandName: [],
                originName: [],
                typeName: [],
                grapeName: [],
            },
            queryParams: {
                query: this.initialQuery,
                keyword: this.initialKeyword,
                brand: this.initialBrandSlug,
                // category: this.initialCategorySlug,
                tag: this.initialTagSlug,
                attribute: this.initialAttribute,
                fromPrice: 0,
                toPrice: this.maxPrice,
                sort: this.initialSort,
                perPage: this.initialPerPage,
                page: this.initialPage,
                grapeMulti: this.initialGrapeSlug ? [this.initialGrapeSlug] : [],
                categoryMulti: this.initialCategorySlug ? [this.initialCategorySlug] : [this.initialCategoryMultiSlug],
                brandMulti: this.initialBrandSlug ? [this.initialBrandSlug] : [],
                originMulti: this.initialOriginSlug ? [this.initialOriginSlug] : [],
                originSlug: this.initialOriginSlug,
                typeSlug: this.initialTypeSlug,
                type: this.initialTypeSlug,
                grapeSlug: this.initialGrapeSlug,
                rootCategorySlug: this.initialRootCategorySlug
            },
        };
    },

    computed: {
        brand() {
            return this.searchedName.brandName.join(', ');
        },

        category() {
            return this.searchedName.categoryName.join(', ');
        },

        grape() {
            return this.searchedName.grapeName.join(', ');
        },

        origin() {
            return this.searchedName.originName.join(', ');
        },

        type() {
            return this.searchedName.typeName.join(', ');
        },

        emptyProducts() {
            return this.products.data.length === 0;
        },

        totalPage() {
            return Math.ceil(this.products.total / this.queryParams.perPage);
        },

        showingResults() {
            if (this.emptyProducts) {
                return;
            }
            return trans("storefront::products.showing_results", {
                from: this.products.from,
                to: this.products.to,
                total: this.products.total,
            });
        },
    },

    mounted() {
        this.addEventListeners();
        this.initPriceFilter();
        this.fetchProducts();
        this.fetchBrands();
        this.fetchOrigins();
        this.fetchGrapes();
        // this.initLatestProductsSlider();
    },

    methods: {
        addEventListeners() {
            $(this.$refs.sortSelect).on("change", (e) => {
                this.queryParams.sort = e.currentTarget.value;

                this.fetchProducts();
            });

            $(this.$refs.perPageSelect).on("change", (e) => {
                this.queryParams.perPage = e.currentTarget.value;

                this.fetchProducts();
            });
        },

        initPriceFilter() {
            noUiSlider.create(this.$refs.priceRange, {
                connect: true,
                direction: window.FleetCart.rtl ? "rtl" : "ltr",
                start: [this.minPrice, this.maxPrice],
                range: {
                    min: [this.minPrice],
                    max: [this.maxPrice],
                },
            });

            this.$refs.priceRange.noUiSlider.on("update", (values, handle) => {
                let value = Math.round(values[handle]);

                if (handle === 0) {
                    this.queryParams.fromPrice = value;
                } else {
                    this.queryParams.toPrice = value;
                }
            });

            this.$refs.priceRange.noUiSlider.on("change", this.fetchProducts);
        },

        updatePriceRange(fromPrice, toPrice) {
            this.$refs.priceRange.noUiSlider.set([fromPrice, toPrice]);

            this.fetchProducts();
        },

        toggleAttributeFilter(slug, value) {
            if (!this.queryParams.attribute.hasOwnProperty(slug)) {
                this.queryParams.attribute[slug] = [];
            }

            if (this.queryParams.attribute[slug].includes(value)) {
                this.queryParams.attribute[slug].splice(
                    this.queryParams.attribute[slug].indexOf(value),
                    1
                );
            } else {
                this.queryParams.attribute[slug].push(value);
            }

            this.fetchProducts({updateAttributeFilters: false});
        },

        isFilteredByAttribute(slug, value) {
            if (!this.queryParams.attribute.hasOwnProperty(slug)) {
                return false;
            }

            return this.queryParams.attribute[slug].includes(value);
        },

        changeCategory(category) {
            this.categoryName = category.name;
            this.categoryBanner = category.banner.path;
            // this.queryParams.query = null;
            if (this.queryParams.categoryMulti.includes(category.slug)) {
                let index = this.queryParams.categoryMulti.indexOf(category.slug);
                this.queryParams.categoryMulti.splice(index, 1);
                this.categoryName = '';
            } else {
                this.queryParams.categoryMulti = [...this.queryParams.categoryMulti, category.slug];
                this.categoryName = category.name;
            }

            if (this.searchedName.categoryName.includes(category.name)) {
                let index = this.searchedName.categoryName.indexOf(category.name);
                this.searchedName.categoryName.splice(index, 1);
            } else {
                this.searchedName.categoryName = [...this.searchedName.categoryName, category.name];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
            this.fetchBrands();
            this.fetchOrigins();
            this.fetchGrapes();
        },

        changeSingleCategory(category) {
            this.categoryName = category.name;
            this.categoryBanner = category.banner.path;

            if (this.queryParams.categoryMulti.includes(category.slug)) {
                let index = this.queryParams.categoryMulti.indexOf(category.slug);
                this.queryParams.categoryMulti.splice(index, 1);
            } else {
                this.queryParams.categoryMulti = [...this.queryParams.categoryMulti, category.slug];
            }

            if(this.queryParams.categoryMulti.length >= 2 && this.queryParams.categoryMulti.includes(this.rootCategorySlug)) {
                const index = this.queryParams.categoryMulti.indexOf(this.rootCategorySlug);
                this.queryParams.categoryMulti.splice(index, 1);
            }

            if (this.searchedName.categoryName.includes(category.name)) {
                let index = this.searchedName.categoryName.indexOf(category.name);
                this.searchedName.categoryName.splice(index, 1);
            } else {
                this.searchedName.categoryName = [...this.searchedName.categoryName, category.name];
            }

            if (this.queryParams.categoryMulti.length == 0 && !this.queryParams.categoryMulti.includes(this.rootCategorySlug)) {
                this.queryParams.categoryMulti = [...this.queryParams.categoryMulti, this.rootCategorySlug];
            }

            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
            this.fetchBrands();
            this.fetchOrigins();
            this.fetchGrapes();
        },

        changeCategoryParent(category) {
            this.categoryName = category.name;
            this.categoryBanner = category.banner.path;
            // this.queryParams.query = null;
            if(this.queryParams.category == category.slug) {
                this.queryParams.category = null;
            }
            else {
                this.queryParams.category = category.slug;
            }

            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
            this.fetchBrands();
            this.fetchOrigins();
            this.fetchGrapes();
        },

        changeGrape(grape) {
            if (this.queryParams.grapeMulti.includes(grape.slug)) {
                let index = this.queryParams.grapeMulti.indexOf(grape.slug);
                this.queryParams.grapeMulti.splice(index, 1);
                this.grapeName = '';
            } else {
                this.queryParams.grapeMulti = [...this.queryParams.grapeMulti, grape.slug];
                this.grapeName = grape.name;
            }

            if (this.searchedName.grapeName.includes(grape.name)) {
                let index = this.searchedName.grapeName.indexOf(grape.name);
                this.searchedName.grapeName.splice(index, 1);
            } else {
                this.searchedName.grapeName = [...this.searchedName.grapeName, grape.name];
            }

            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
        },

        changeOrigin(origin) {
            if (this.queryParams.originMulti.includes(origin.slug)) {
                let index = this.queryParams.originMulti.indexOf(origin.slug);
                this.queryParams.originMulti.splice(index, 1);
                this.originName = '';
            } else {
                this.queryParams.originMulti = [...this.queryParams.originMulti, origin.slug];
                this.originName = origin.name;
            }

            if (this.searchedName.originName.includes(origin.name)) {
                let index = this.searchedName.originName.indexOf(origin.name);
                this.searchedName.originName.splice(index, 1);
            } else {
                this.searchedName.originName = [...this.searchedName.originName, origin.name];
            }

            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
        },

        changeBrand(brand) {
            // this.queryParams.query = null;
            if (this.queryParams.brandMulti.includes(brand.slug)) {
                let index = this.queryParams.brandMulti.indexOf(brand.slug);
                this.queryParams.brandMulti.splice(index, 1);
                this.brandName = '';
            } else {
                this.queryParams.brandMulti = [...this.queryParams.brandMulti, brand.slug];
                this.brandName = brand.name;
            }

            if (this.searchedName.brandName.includes(brand.name)) {
                let index = this.searchedName.brandName.indexOf(brand.name);
                this.searchedName.brandName.splice(index, 1);
            } else {
                this.searchedName.brandName = [...this.searchedName.brandName, brand.name];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchProducts();
        },

        changePage(page) {
            this.queryParams.page = page;

            this.fetchProducts();
        },

        changeCategoryMobile(category) {
            this.categoryName = category.name;
            this.categoryBanner = category.banner.path;
            // this.queryParams.query = null;
            if (this.queryParams.categoryMulti.includes(category.slug)) {
                let index = this.queryParams.categoryMulti.indexOf(category.slug);
                this.queryParams.categoryMulti.splice(index, 1);
            } else {
                this.queryParams.categoryMulti = [...this.queryParams.categoryMulti, category.slug];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;

            this.fetchBrands();
            this.fetchOrigins();
            this.fetchGrapes();
        },

        changeBrandMobile(brand) {
            this.brandName = brand.name;
            // this.queryParams.query = null;
            if (this.queryParams.brandMulti.includes(brand.slug)) {
                let index = this.queryParams.brandMulti.indexOf(brand.slug);
                this.queryParams.brandMulti.splice(index, 1);
            } else {
                this.queryParams.brandMulti = [...this.queryParams.brandMulti, brand.slug];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;
        },

        changeGrapeMobile(grape) {
            if (this.queryParams.grapeMulti.includes(grape.slug)) {
                let index = this.queryParams.grapeMulti.indexOf(grape.slug);
                this.queryParams.grapeMulti.splice(index, 1);
            } else {
                this.queryParams.grapeMulti = [...this.queryParams.grapeMulti, grape.slug];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;
        },

        changeOriginMobile(origin) {
            if (this.queryParams.originMulti.includes(origin.slug)) {
                let index = this.queryParams.originMulti.indexOf(origin.slug);
                this.queryParams.originMulti.splice(index, 1);
            } else {
                this.queryParams.originMulti = [...this.queryParams.originMulti, origin.slug];
            }
            this.queryParams.attribute = {};
            this.queryParams.page = 1;
        },

        changeSortMobile(sort) {
            this.queryParams.sort = sort;

        },

        submitFetchProducts() {
            this.fetchProducts();
        },

        async fetchProducts(options = {updateAttributeFilters: true}) {
            this.fetchingProducts = true;

            try {
                const response = await axios.get(
                    route("products.index", this.queryParams)
                );

                this.products = response.data.products;

                if (options.updateAttributeFilters) {
                    this.attributeFilters = response.data.attributes;
                }
            } catch (error) {
                // this.$notify(error.response.data.message);
            } finally {
                this.fetchingProducts = false;
            }
        },

        async fetchBrands() {
            this.fetchingBrands = true;

            try {
                const response = await axios.get(
                    route("brands", this.queryParams)
                );

                this.brands = response.data.brands.filter(brand => brand.name);
            } catch (error) {
                // this.$notify(error.response.data.message);
            } finally {
                this.fetchingBrands = false;
            }
        },

        async fetchOrigins() {
            this.fetchingOrigins = true;

            try {
                const response = await axios.get(
                    route("origins", this.queryParams)
                );

                this.origins = response.data.origins.filter(origin => origin.name);
            } catch (error) {
                // this.$notify(error.response.data.message);
            } finally {
                this.fetchingOrigins = false;
            }
        },

        async fetchGrapes() {
            this.fetchingGrapes = true;

            try {
                const response = await axios.get(
                    route("grape_varieties", this.queryParams)
                );

                this.grapes = response.data.grapes.filter(grape => grape.name);
            } catch (error) {
                // this.$notify(error.response.data.message);
            } finally {
                this.fetchingGrapes = false;
            }
        }

        // initLatestProductsSlider() {
        //     $(this.$refs.latestProducts).slick({
        //         rows: 0,
        //         dots: false,
        //         arrows: true,
        //         infinite: true,
        //         slidesToShow: 1,
        //         slidesToScroll: 1,
        //         rtl: window.FleetCart.rtl,
        //     });
        // },
    },
};
