<template>
    <div class="explore-wine" v-if="countListCategories > 0">
        <div class="explore-wine-title">
            <h2 class="section-title-menu">
                {{ sectionData.title }}
            </h2>
            <p class="sub-title-menu" v-if="sectionData.subtitle">{{ sectionData.subtitle }}</p>
        </div>
        <div class="explore-wine-grid">
            <div class="first-row" v-if="listCategories">
                <div v-for="item in listCategories.slice(0, 2)" :key="item.id" class="wine-item">
                    <div class="image-wrapper">
                        <img :src="item.banner || 'build/assets/wine-1.png'" alt="category-banner">
                        <div class="gradient-overlay"></div>
                    </div>
                    <div class="content">
                        <a :href="'danh-muc/' + item.slug + '/san-pham'" class="wine-name">{{ item.name }}</a>
                        <a :href="'danh-muc/' + item.slug + '/san-pham'" class="icon-more-wine">
                            {{ $trans('storefront::layout.view_more') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="second-row" v-if="listCategories && countListCategories >= 3">
                <div v-for="item in listCategories.slice(2, countListCategories)" :key="item.id" class="wine-item">
                    <div class="image-wrapper">
                        <img :src="item.banner || 'build/assets/wine-1.png'" alt="category-banner">
                        <div class="gradient-overlay"></div>
                    </div>
                    <div class="content">
                        <a :href="'danh-muc/' + item.slug + '/san-pham'" class="wine-name">{{ item.name }}</a>
                        <a :href="'danh-muc/' + item.slug + '/san-pham'" class="icon-more-wine">
                            {{ $trans('storefront::layout.view_more') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ExploreWine",
    props: {
        data: Object,
    },
    computed: {
        listCategories() {
            return this.data.categories.filter((item) => item.name);
        },
        countListCategories() {
            return this.listCategories.length;
        },
    },
    data() {
        return {
            sectionData : this.data,
        };
    },
}
</script>
