import "./vendors/vendors";

$(() => {
    /*      variables
    /*----------------------------------------*/

    let _window = $(window),
        body = $("body");

    /*      button loading
    /*----------------------------------------*/

    $("[data-loading]").on("click", (e) => {
        e.currentTarget.classList.add("btn-loading");
    });

    /*      select option
    /*----------------------------------------*/

    let select = $(".custom-select-option");

    select.niceSelect();

    select.on("change", (e) => {
        e.target.dispatchEvent(
            new Event("nice-select-updated", { bubbles: true })
        );
    });

    /*      overlay
    /*----------------------------------------*/

    let overlay = $(".overlay");

    /*      header
    /*----------------------------------------*/

    let headerWrap = $(".header-wrap"),
        placeholder = $("#header-placeholder"),
        headerSearchSm = $(".header-search-sm"),
        headerSearchSmForm = $(".header-search-sm-form"),
        searchInputSm = $(".search-input-sm"),
        headerSearchSmClose = $(".header-search-sm-form .btn-close");

    let headerWrapHeight = headerWrap.outerHeight();

    function createPlaceholder() {
        if (placeholder.length === 0 && (window.innerWidth <= 768 || !headerWrap.next().hasClass('slide-banner'))) {
            placeholder = $("<div id='header-placeholder'></div>").css({
                height: headerWrapHeight,
                display: 'none',
                background: '#000'
            });
            headerWrap.after(placeholder);
        }
    }

    function handleScroll() {
        createPlaceholder();
        if (headerWrap.next().hasClass("slide-banner") && window.innerWidth > 768) {
            headerWrap.css("background-color", window.scrollY > 0 ? "#000" : "");
        } else {
            if (window.scrollY > 0) {
                headerWrap.css("position", "fixed").addClass("fixed-top");
                placeholder.show();
            } else {
                headerWrap.css("position", "").removeClass("fixed-top");
                placeholder.hide();
            }
        }
    }

    _window.on("scroll", () => { handleScroll(); });

    let previousWidth = _window.width();
    _window.on("resize", () => {
        let currentWidth = _window.width();

        if (currentWidth !== previousWidth) {
            $("#header-placeholder").remove();
            placeholder = $();
            headerWrapHeight = headerWrap.outerHeight();
            headerWrap.css({
                "position": "",
                "background-color": ""
            }).removeClass("fixed-top");

            previousWidth = currentWidth;
        }
    });

    headerSearchSm.on("click", (e) => {
        e.stopPropagation();

        headerSearchSmForm.toggleClass("active");
        body.addClass("no-scroll");
        searchInputSm.trigger("focus");

        overlay.addClass("active");
    });

    headerSearchSmClose.on("click", (e) => {
        let target = $(e.currentTarget);
        body.removeClass("no-scroll");

        target.parents(".header-search-sm-form").removeClass("active");
        overlay.removeClass("active");
    });

    /*      menu dropdown arrow
    /*----------------------------------------*/

    let megaMenuItem = $(".mega-menu > li"),
        subMenuDropdown = $(".sub-menu > .dropdown"),
        sidebarMenuSubMenu = $(".sidebar-menu .sub-menu"),
        subMenuConetent = $(".sub-menu-content .sub-menu-item ");

    function menuDropdownArrow(parentSelector, childSelector) {
        parentSelector.each(function () {
            let self = $(this);

            if (self.children().length > 1) {
                // if (window.FleetCart.rtl) {
                //     self.children(`${childSelector}`).append(
                //         '<i class="las la-angle-up"></i>'
                //     );

                //     return;
                // }

                self.children(`${childSelector}`).append(
                    '<i class="las la-angle-down"></i>'
                );
            }
        });
    }

    menuDropdownArrow(subMenuConetent, "div");

    /*      navigation
    /*----------------------------------------*/

    let moreCategories = $(".more-categories"),
        categoryDropdown = $(".category-dropdown"),
        categoryNavInner = $(".category-nav-inner"),
        categoryDropdownWrap = $(".category-dropdown-wrap"),
        verticalMegaMenuList = $(".vertical-megamenu > li");

    categoryNavInner.on("click", () => {
        categoryDropdownWrap.toggleClass("show");
    });

    _window.on("load resize", () => {
        let verticalMegaMenuListHeight = 0,
            homeSliderHeight = homeSlider.height(),
            categoryDropdownHeight = homeSliderHeight;

        categoryDropdown.css("height", `${categoryDropdownHeight}px`);

        verticalMegaMenuList.each(function () {
            let self = $(this);

            verticalMegaMenuListHeight += self.height();

            if (verticalMegaMenuListHeight + 78 > categoryDropdownHeight) {
                self.addClass("hide");
                moreCategories.removeClass("hide");

                return;
            }

            self.removeClass("hide");
            moreCategories.addClass("hide");
        });
    });

    /*      sidebar menu
    /*----------------------------------------*/

    let sidebarMenuIcon = $(".sidebar-menu-icon"),
        sidebarMenuWrap = $(".sidebar-menu-wrap"),
        sidebarMenuClose = $(".sidebar-menu-close"),
        sidebarMenuTab = $(".sidebar-menu-tab a"),
        sidebarMenuList = $(".sidebar-menu li"),
        sidebarMenuLink = $(".sidebar-menu > li > a"),
        sidebarMenuListUl = $(".sidebar-menu > li > ul"),
        sidebarMenuDropdown = $(".sidebar-menu > .dropdown"),
        sidebarMenuSubMenuUl = $(".sidebar-menu .sub-menu ul"),
        sidebarMenuSubMenuLink = $(".sidebar-menu .sub-menu > a");

    sidebarMenuIcon.on("click", (e) => {
        e.stopPropagation();

        overlay.addClass("active");
        sidebarMenuWrap.addClass("active");
        body.addClass("no-scroll");
    });

    sidebarMenuClose.on("click", (e) => {
        overlay.removeClass("active");
        sidebarMenuWrap.removeClass("active");
        body.removeClass("no-scroll");
    });

    sidebarMenuWrap.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuTab.on("click", (e) => {
        let target = $(e.currentTarget);

        e.preventDefault();
        target.tab("show");
    });

    sidebarMenuList.each(function () {
        let self = $(this);

        if (self.children().length > 1) {
            if (window.FleetCart.rtl) {
                self.children("a").after('<i class="las la-angle-left"></i>');

                return;
            }

            self.children("a").after('<i class="las la-angle-right"></i>');
        }
    });

    sidebarMenuLink.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuListUl.on("click", (e) => {
        e.stopPropagation();
    });

    sidebarMenuSubMenu.on("click", (e) => {
        let target = $(e.currentTarget);

        if (!target.hasClass("active")) {
            target.addClass("active");
        } else {
            target.removeClass("active");
        }

        target.children("ul").slideToggle(300);
    });

    sidebarMenuSubMenuUl.on("click", function (e) {
        e.stopPropagation();
    });

    sidebarMenuSubMenuLink.on("click", (e) => {
        e.stopPropagation();
    });

    /*      slider
    /*----------------------------------------*/

    const homeSlider = $(".home-slider");

    if (homeSlider.length !== 0) {
        const { speed, autoplay, autoplaySpeed, fade, dots, arrows } =
            homeSlider.data();

        homeSlider
            .slick({
                rows: 0,
                rtl: window.FleetCart.rtl,
                cssEase: fade ? "cubic-bezier(0.7, 0, 0.3, 1)" : "ease",
                speed,
                autoplay,
                autoplaySpeed,
                fade,
                dots,
                arrows,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                        },
                    },
                ],
            })
            .slickAnimation();
    }

    /*      sidebar filter
    /*----------------------------------------*/

    let mobileViewFilter = $(".mobile-view-filter");
    let filterSectionWrap = $(".filter-section-wrap");
    let sidebarFilterClose = $(".sidebar-filter-close");

    mobileViewFilter.on("click", (e) => {
        e.stopPropagation();

        filterSectionWrap.addClass("active");
        overlay.addClass("active");
    });

    sidebarFilterClose.on("click", () => {
        filterSectionWrap.removeClass("active");
        overlay.removeClass("active");
    });

    filterSectionWrap.on("click", (e) => {
        e.stopPropagation();
    });

    body.on("click", () => {
        $(".sidebar-cart-wrap").removeClass("active");
        sidebarMenuWrap.removeClass("active");
        filterSectionWrap.removeClass("active");
        body.removeClass("no-scroll");
        if (!searchInputSm.is(":focus")) {
            overlay.removeClass("active");
            let headerSearch = $(".header-search");
            headerSearch.next().removeClass("active");
        }
    });

    /*      browse categories
    /*----------------------------------------*/

    // $(".browse-categories li").each((_, li) => {
    //     if ($(li).children("ul").length > 0) {
    //         $(li).addClass("parent");
    //     }
    // });

    let filterCategoriesLink = $(".browse-categories li.attribute-item > a");
    // let parentUls = $(".browse-categories li.active").parentsUntil(
    //     ".browse-categories",
    //     "ul"
    // );
    //
    if (window.FleetCart.rtl) {
        filterCategoriesLink.before('<i class="las la-angle-up"></i>');
    } else {
        filterCategoriesLink.before('<i class="las la-angle-down"></i>');
    }
    //
    // parentUls.show().siblings("i").addClass("open");
    //
    // $(".browse-categories li i").on("click", (e) => {
    //     $(e.currentTarget).toggleClass("open").siblings("ul").slideToggle(300);
    // });


    $(window).on('scroll', function () {
        var scrollPos = $(document).scrollTop();
        $('.section-step').each(function (index) {
            var sectionTop = $(this).offset().top - 150;
            var sectionHeight = $(this).outerHeight();
            let value = index + 1;
            if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
                console.log(value);
                $('input[name="flow_step"][value="' + value + '"]').prop('checked', true);
            }
        });
    });

    $('.content-left__flows input[type="radio"]').on('change', function() {
        var sectionId = $(this).val();
        $('html, body').animate({
            scrollTop: $('#flow-step-' + sectionId).offset().top - 120
        }, 1000);
    });

    $(document).ready(function() {
        // Handle click event on list items
        $('.tabs-list .tabs-item').on('click', function() {
            // Remove 'active' class from all list items
            $('.tabs-list .tabs-item').removeClass('active');
            // Add 'active' class to the clicked list item
            $(this).addClass('active');
        });

        // Handle click event on the continue button
        $('#btn-choose-register-type').on('click', function() {
            // Get the URL from the active list item
            var activeItem = $('.tabs-list .tabs-item.active');
            if (activeItem.length > 0) {
                var url = activeItem.data('url');
                // Redirect to the URL
                window.location.href = url;
            } else {
                alert('Please select an option before continuing.');
            }
        });
    });

    /*      Handle Popup Shipping Note : Themes\Storefront\views\public\cart\index.blade.php
    /*----------------------------------------*/
    $(document).ready(function() {
        $('.popup-shipping').on('click', function(event) {
            if ($(event.target).is('.popup-shipping')) {
                $(this).removeClass('active');
            }
        });

        $('.popup-fee-note').on('click', function(e) {
            e.preventDefault();
            $('.popup-shipping').addClass('active');
        });

        $('.popup-close').on('click', function(e) {
            e.preventDefault();
            $('.popup-shipping').removeClass('active');
        });
    });

    $('.header-language').on('click', function() {
        $('.select-language').toggleClass('active');
    });

    $(document).on('click', function(event) {
        if (!$(event.target).closest('.header-language').length) {
            $('.select-language').removeClass('active');
        }
    });
});
