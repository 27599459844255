<template>
    <div class="grid-view-products-item">
        <a :href="'san-pham/' + encodeURIComponent(product.slug)" :key="product.id">
            <ProductCard :product="product" />
        </a>
    </div>
</template>

<script>
import ProductCard from "./../../ProductCard.vue";

export default {
    components: { ProductCard },

    props: ["product"],
};
</script>
