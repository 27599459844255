<template>
    <div class="product-card">
        <div class="product-image">
            <img :src="item.base_image_path ?? defaultImage" :alt="item.name" />
            <div class="new-product" :style="{
                backgroundImage: 'url(' + NewProductRedBg + ')'
            }" v-if="item.is_new_product">
                <span>{{ $trans("storefront::product_card.new") }}</span>
            </div>
        </div>
        <div class="product-details">
            <div class="infor">
                <div class="product-name">
                    {{ item.name }}
                </div>
                <div class="product-detail-infor">
                    <p v-if="item.bottle_size_variant_name">{{ item.bottle_size_variant_name }}</p>
                    <p v-if="item.abv">{{ item.abv }}</p>
                </div>
            </div>
            <div class="price">{{ $trans("storefront::product_card.call_for_price") }}</div>
        </div>
    </div>
</template>

<script>
import ProductCardMixin from "../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],
    props: ["product"],
    data() {
        return {
            NewProductRedBg: "build/assets/new_product_red_bg.svg",
            defaultImage: "build/assets/default_product.png",
        };
    },
    computed: {
        item() {
            return {
                // ...(this.product.variant ? this.product.variant : this.product),
                ...(this.product),
            };
        },
    },
};
</script>
