<template>
    <!-- <video class="hero-video" 
        controls 
        autoplay
        playsinline
        muted
        >
        <source :src="videos.youtube_video" type="video/mp4" >
        Your browser does not support the video tag.
    </video> -->
    <div v-if="videos">
        <div v-if="isEmbedHtml(videos.youtube_video)" v-html="videos.youtube_video"></div>
        
        <div v-else-if="isYouTubeUrl(videos.youtube_video)">
            <iframe
                class="hero-video"
                width="100%"
                height="778"
                :src="'https://www.youtube.com/embed/' + extractVideoId(videos.youtube_video)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    name: "YoutubeVideo",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String
    },
    data() {
        return {
            videos: '',
            isVideoPlaying: true
        };
    },
    mounted() {
        // Log the video variable to check if it's null or contains data
        // console.log(this.videos); // Corrected this line to reference `this.videos`
    },
    beforeMount() {
        // Parse the incoming data and set videos variable
        this.videos = JSON.parse(this.data);
    },
    methods: {
        extractVideoId(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.*v=)([^\s&]+)/;
            const match = url.match(regex);
            return match ? match[1] : '';
        },
        isEmbedHtml(value) {
            return typeof value === 'string' && value.includes('<iframe');
        },
        isYouTubeUrl(value) {
            const youtubeRegex = /youtube\.com\/watch\?v=/;
            return typeof value === 'string' && youtubeRegex.test(value);
        },
        unmuteVideo(event) {
            event.target.muted = false;
        },
    }
}
</script>
